/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  store = {
    product: {
      inStore: {
        title: 'Atención',
        message: 'Este artículo ya se encuentra en el carrito de compra. ¿Deseas sumarlo a tu pedido?',
        icon: false,
        decline: 'No, conservar mi pedido actual.',
        accept: 'Si, sumarlo a mi pedido.',
      },
      added: {
        title: 'Agregado al carrito',
        message: 'Se ha agregado este producto al carrito.',
        type: 'success',
      },
      updated: {
        quantity: {
          title: 'Carrito actualizado',
          message: 'Se ha actualizado el producto.',
          type: 'info',
          color: 'green',
        }
      },
      removed: {
        title: 'Eliminado del carrito',
        message: 'Se ha eliminado este producto del carrito',
        type: 'info',
        color: 'red',
      },
    },
    code: {
      valid: {
        title: 'Código válido',
        message: 'El código promocional agregado es válido y se ha añadido a tu pedido.',
        type: 'success',
      },
      invalid: {
        title: 'Código inválido',
        message: 'El código promocional es inválido, por favor verifica que sea correcto.',
        type: 'error',
      }
    },
    shipping: {
      postal: {
        set: {
          title: 'Código postal agregado',
          message: 'Se ha agregado el código postal a tu pedido.',
          type: 'info',
          color: 'green',
        },
        updated: {
          title: 'Código postal actualizado',
          message: 'Se ha actualizado el código postal de tu pedido.',
          type: 'info',
          color: 'green',
        }
      },
      updated: {
        standard: {
          title: 'Envío actualizado',
          message: 'Se ha actualizado el tipo de envío para tu orden, verifica el nuevo costo.',
          color: 'yellow',
          type: 'info'
        },
        gratuito: {
          title: 'Envío actualizado',
          message: '¡Genial, ahora tu envío ahora es gratis!',
          color: 'green',
          type: 'info'
        },
        plus: {
          title: 'Envío actualizado',
          message: '¡Excelente! Tu costo de envío ahora tiene una tarifa preferente.',
          color: 'green',
          type: 'info'
        },
        premium: {
          title: 'Envío actualizado',
          message: 'Una gran noticia! Hemos mejorado la tarifa de tu envío.',
          color: 'green',
          type: 'info'
        },
        price: {
          title: 'Costo de envío actualizado',
          message: 'La tarifa de tu envío ha cambiado, por favor verifícala.',
          color: 'yellow',
          type: 'info'
        }
      }
    },
    cart: {
      empty: {
        title: 'Carrito vacío',
        message: 'Hemos vaciado tu carrito de compra, ahora hay suficiente espacio para otros productos.',
        type: 'warning',
      }
    }
  };

  function toast( params ) {
    var type = '';
    var sets = {};

    if(params !== undefined ){
      type = params.type;
      sets = params;
    }
    
    switch ( type ) {
      case 'info':
        iziToast.info( sets );
        break;
      case 'success':
        iziToast.success( sets );
        break;
      case 'warning':
        iziToast.warning( sets );
        break;
      case 'error':
        iziToast.error( sets );
        break;
      case 'question':
        iziToast.error( sets );
        break;
      default:
        iziToast.show( sets );
        break;
    }
  }

  function renderModal( node ) {
    html  = '';
    html += '<p class="h1 text-center">' + node.title;
    html += ( node.icon    !== false )?'<i class="'+ node.icon +'"></i>':''; html += '</p>';
    html += ( node.message !== false )?'<p class="text-center">'+ node.message +'</p>':'';
    html += '<div class="text-center">';
    html += ( node.accept  !== false )?'<button class="btn d-inline-block d-sm-inline-block d-block mx-auto mt-1 mx-sm-1 mt-sm-0 btn-success store__modal__button" data-store-process-accept>'+ node.accept +'</button>':'';
    html += ( node.decline !== false )?'<button class="btn d-inline-block d-sm-inline-block d-block mx-auto mt-1 mx-sm-1 mt-sm-0 btn-danger" data-store-process-decline>'+ node.decline +'</button>':'';
    html += '</div>';
    return html;
  }

  function resetForm( button ) {
    if(button.parents('form').length){
      button.parents('form')[0].reset();
    }
    button.removeAttr('disabled');
    $('i', button).removeClass('old-processing');
    $('i.fa.processing', button).remove();
  }

  function startProcess( button ) {
    button.attr('disabled', 'disabled');
    $('i', button).addClass('old-processing');
    button.prepend('<i class="fa fa-circle-o-notch fa-spin processing"></i>');
  }

  function updateBadge( total ){
    $("#store__badge")
      .addClass('zoomed')
      .text( total )
      .delay(1000)
      .queue(function(){
        $(this).removeClass('zoomed').dequeue();
      });
    if( total> 0) {
      $(".header__nav-icon--store").addClass('has-products').attr('href', '/carrito/');
    }
    else {
      $(".header__nav-icon--store").removeClass('has-products').attr('href', '/productos/');
    }
  }

  function updateShipping(name, postal, price){
    if( name === null || postal === null ) {
      return;
    }

    var button = $('[name=button__zip-code]'),
        ship_actual = name,
        ship_postal = postal,
        set = false;

    if( button.attr('data-store-shipping') === 'true' ){
      ship_actual = $('#shipping_service_name').text();
      ship_price  = $('#shipping_service_price').text();
      ship_postal = button.attr('data-store-shipping-zip');
      set = true;
    }

    if( !set ){
        toast( store.shipping.postal.set );
        button.attr('data-store-shipping-zip', postal);
        button.attr('data-store-shipping', 'true');
    }
    else {
      if ( ship_postal !== postal ) {
        toast( store.shipping.postal.updated );
        button.attr('data-store-shipping-zip', postal);
      }
      if( parseFloat( price ) > parseFloat( ship_price ) ){
        toast( store.shipping.updated.price );
        return;
      }
      if ( ship_actual !== name ){
        name = name.toLowerCase();
        if( name.indexOf('gratuito') !== -1 ){
          toast( store.shipping.updated.gratuito );
        }
        if( name.indexOf('premium') !== -1 ){
          toast( store.shipping.updated.premium );
        }
        if( name.indexOf('plus') !== -1 ){
          toast( store.shipping.updated.plus );
        }
        if( name.indexOf('ndar') !== -1 ){
          toast( store.shipping.updated.standard );
        }
      }
    }
  }

  function updateCartAmounts(){
    var update = { action: 'cartresume' };
    $.ajax({
      url: '/cart/',
      type: 'POST',
      data: update,
      success: function(resume){    
        if( resume.status !== 'error' ){
          var count     = resume.data.count;
          var subtotal  = resume.data.subtotal;
          var shipping  = resume.data.shipping;
          var shipname  = resume.data.shipping_name;
          var shipzip   = resume.data.zip;
          var total     = resume.data.total;
          updateBadge( count );
          updateShipping(shipname, shipzip, shipping);
          $('#items_cart_count').html( count );
          $('#subtotal_cart_sum').html( subtotal );
          $('#total_cart_sum').html( total );
          if( shipname !== null){
            $('#shipping_service_name').html( shipname );
          }
          if( shipping !== null){
            $('#shipping_service_price').html( shipping );
          }
        }
      }
    });
  }

  function updateCart(total){
    if( total < 1 ){
      $('.store__cart--empty-cart').removeClass('d-none');
      $('.store__cart--detail, .store__cart--final-button').fadeOut().remove(); 
      updateBadge( total );
    } else {
      updateCartAmounts();
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var md = new MobileDetect(window.navigator.userAgent);
        $class = (md.mobile() !== null) ? 'mobile' : '';
        $('body').addClass($class);

        if ($('.select2').length) {
          $('body').not('.woocommerce-page').find('.select2').select2({ minimumResultsForSearch: -1 });
        }

        modal = $('.store__modal');
        
        if (modal.length) {
          modal.iziModal();
          if ($('[data-store-process-decline]').length) {
            $('[data-store-process-decline]').live('click', function () {
              modal.iziModal('close');
              resetForm($('[data-store-action]'));
            });
          }          
        }


        $('.offcanvas-main-menu').css('opacity', 1);

        if($('.grid').length) {

          var msnry = new Masonry( '.grid', {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            gutter: 0,
            percentPosition: true
          });

          var base = 'page/';
          var strt = 2;

          if( (window.location.href.indexOf("productos") > -1) || (window.location.href.indexOf("guia-de-aceites") > -1) ) {
            base = '';
          }

          $('.grid.posts-container.infinite-scroll').infiniteScroll({
            path: function() {
                var nextIndex = (this.loadCount > 0) ? (this.loadCount + strt) : strt;
                return base + nextIndex;
            },
            checkLastPage: 'nav.posts-navigation .nav-previous a',
            hideNav: 'nav.posts-navigation',
             append: '.grid-post',
             history: false,
             historyTitle: false,
             outlayer: msnry,
             scrollThresold: 50,
             prefill: true,
             status: '.load-status'
           });
        }

        AOS.init();

        $('.main-menu-toggler, .main-menu-close').click(function(e){
            e.preventDefault();
        });

        $('.header__nav-icon--search, .reset-form').click(function(e) {
          e.preventDefault();
          $('.search-header').toggleClass('show');
          $('body').toggleClass('search-mode');
          $('.search-header .search-header__form')[0].reset();
          if( $('.search-header').is('.show') ) {
            $('.search-header .search-header__form__input').focus();
          }
        });

        $( '.search-header .search-header__form__input' ).keyup(function() {
          if($(this).val().trim().length > 0){
            $(this).parent().not(".filled").addClass('filled');
          } else{
            $(this).parent().removeClass('filled');
          }
        });

        $( '#sidebar.offcanvas-main-menu' ).simplerSidebar({
            align: 'left',
            animation: {
              duration: 150
            },
            selectors: {
              trigger: '.main-menu-toggler',
              quitter: '.main-menu-close'
            },
            sidebar: {
              width: 425
            },
            mask: {
               display: true,
               css: {
                  backgroundColor: 'white',
                  opacity: 0.1,
                  filter: 'Alpha(opacity=10)'
               }
           },
           events: {
              callbacks: {
                  animation: {
                      open: function(){

                        if( !$('body.mobile').length ){
                          $('.offcanvas-main-menu').append( '<div class="body-cloned"><div class="body-cloned__wrapper"></div></div>' );
                          $('.offcanvas-main-menu .body-cloned__wrapper')
                            .append( $( 'body' ).clone() )
                            .find('.header, script, [data-simplersidebar]').remove();
                          $('.offcanvas-main-menu .body-cloned__wrapper').css('marginTop', (-1*$(document).scrollTop()) ).fadeIn(100);
                          //.foggy({ blurRadius: 8, opacity: 0.8, cssFilterSupport: true });

                          $(window).resize(function(){
                            $('.offcanvas-main-menu .body-cloned__wrapper').css('marginTop', (-1*$(document).scrollTop()) );
                          });
                        }
                      },
                      close: function(){
                        $('.body-cloned').remove();
                      },
                      freezePage: true
                  }
              }
           }
        });

        $(document).scroll(function() {

          var top = $(document).scrollTop();

          if (top > 30){
            $('body').addClass('scrolled');
          }
          else{
            $('body').removeClass('scrolled');
          }

        });

        function fload(d, f){
          var e = "/cart/";
          var c = $("<i>");
          var r;
          c.load( e, { data: d }, f);
        }

        $('[data-store-selector]').change(function(){
          var value = $('option:selected', this).attr('data-product-variant-id');            
        });

        $('[data-store-action]').on('click', function(e){
          e.preventDefault();
          var button = $(this);
          startProcess( button );

          var data = {
            action: button.attr('data-store-action')
          };

          if( button.attr('data-store-productid') ){
            data.productid = button.attr('data-store-productid');
          }

          if( button.attr('data-store-productvar') ){
            data.productvar = button.attr('data-store-productvar');
          }

          if($('[data-store-selector]').length){
            data.productvar   = $('[data-store-selector] option:selected').attr('data-product-variant-id');
            data.productprice = $('[data-store-selector] option:selected').attr('data-product-variant-price');
            data.taxable      = $('[data-store-selector] option:selected').attr('data-product-variant-taxable');
          }

          if($('.number-increments input.input-number').length){
            data.quantity     = $('.number-increments input.input-number').val();
          }

          switch (data.action) {

            case 'add':
              findItem = { action: 'find', productvar: data.productvar };

              fload(findItem, function(e){
                item = jQuery.parseJSON(e);
                var func = function(d){
                  response = jQuery.parseJSON(d);
                  if(response.status !== 'error'){
                    totalItems = { action: "totalItems" };
                    fload(totalItems, function(t){
                      total = jQuery.parseJSON(t);
                      updateBadge( total.data );
                      resetForm( button ); 
                      toast( store.product.added );
                    });
                  }
                };

                if(item.data !== false){
                  modal.find('.container').html(renderModal(store.product.inStore));
                  modal.iziModal('open');
                  $(document).on('closed', modal, function (e) {
                    resetForm( button );
                  });
                  $('[data-store-process-accept]')
                    .unbind('click').on('click', function () {
                      fload(data, func);
                      modal.iziModal('close');
                  });
                } else {
                  fload(data, func);
                }
                
              });
              break;

            case 'remove':
              var remove = { action: data.action, productvar: data.productvar };
              var func = function(d){
                response = jQuery.parseJSON(d);
                if( response.status !== 'error' ){
                  idvar = response.data.product.product_variant;
                  row = $('.product__item [data-item-var=' + idvar + ']').parent();
                  rows = row.children('[data-item-var]').length;
                  
                  if( rows > 1 ){
                    todel = $('[data-item-var=' + idvar + ']', row);
                  }
                  else {
                    todel = row.parents('li.product__item');
                  }

                  totalItems = { action: "totalItems" };
                  fload(totalItems, function(t){
                    total = jQuery.parseJSON(t);
                    todel.fadeOut().remove();
                    resetForm( button );
                    updateCart(total.data);
                    updateBadge(total.data);
                    toast( store.product.removed );
                  });
                }
              };
              fload(remove, func);
              break;

              case 'shipping':
                var ship_actual = '',
                    set = false,
                    postal = $('input[name=zip_code]').val(),
                    shipping = { action: data.action, zip: postal, ep: 'h' };

                if( !postal.length){
                  resetForm( button );
                  return;
                }
                
                fload(shipping, function(t){
                  shipping = jQuery.parseJSON(t);
                  if(shipping.status !== 'error'){
                      updateCartAmounts();
                  }
                  resetForm( button );
                });
                break;
              
              case 'promoCode':
                var field = $('input[name=promo_code]');
                if( !field.length){
                  resetForm( button );
                  return;
                }
                var promocode = { action: data.action, code: field.val() };
                field.removeClass('is-valid').removeClass('is-invalid');
                $('#promo_code_response').text('');
                fload(promocode, function(t){
                  promocode = jQuery.parseJSON(t);
                  if(promocode.status !== 'error'){
                      if( promocode.data !== false ){
                        field.addClass('is-valid');
                        toast( store.code.valid );
                        $('#promo_code_response').text('Este código promocional se aplicará al iniciar el proceso de pago.');
                      } 
                      else{
                        field.addClass('is-invalid');
                        toast( store.code.invalid );
                      }
                  }
                  resetForm( button );
                });
                break;

              case 'checkout':
                var checkout = { action: data.action };
                fload(checkout, function(t){
                  checkoutResponse = jQuery.parseJSON(t);
                  if( checkoutResponse.status !== false ){
                    storefrontCheckout = jQuery.parseJSON(checkoutResponse.status);
                    window.location.href = storefrontCheckout.data.checkoutCreate.checkout.webUrl;
                  }
                  resetForm( button );
                });
                break;
              
              case 'reset':
                var reset = { action: data.action };
                fload(reset, function(t){
                  reset = jQuery.parseJSON(t);
                  if(reset.status === 'success'){
                    updateCart( 0 );
                    resetForm( button );
                    toast( store.cart.empty );
                  }
                });
                break;

              default: break;
          }
        });

        $('[data-store-field-action]').on('change', function (e) {          
          field    = $(this);
          field.removeClass('error');
          if( !field.val().length || isNaN(field.val()) || field.val() <= 0){
            field.addClass('error');
            return;
          }
          fieldbox = field.parent();
          fieldbox.addClass('processing');
          request = {
            action: field.attr('data-store-field-action'),
            productvar: field.attr('data-store-productvar'),
            total: field.val()
          };
          $.ajax({
            url: '/cart/',
            type: 'POST',
            data: request,
            cache: false,
            success: function(update){  
              if( update.status !== 'error' ){
                updated_amount = update.data.rendered;
                toupdate = '#variant__cart__subtotal__' + update.data.variant;
                $(toupdate).html( updated_amount );
                toast( store.product.updated.quantity );
                updateCartAmounts();
              }
            },
            complete: function () {
              field.blur();
              fieldbox.removeClass('processing');
            }
          });
        });
        
        $('.stickybar').stickybits({
          useStickyClasses: true
        });

        $('.match').matchHeight({
          byRow: false,
          property: 'height',
          target: null,
          remove: false
        });

        // Step input numbers
        if ( $('.number-increments').length ){
          $('.number-increments').each(function() {
            $('.btn-number', this).click(function(e) {
              e.preventDefault();
              fieldName = $(this).attr('data-field');
              type      = $(this).attr('data-type');
              var input = $("input[name='"+fieldName+"']");
              var currentVal = parseInt(input.val());
              if (!isNaN(currentVal)) {
                if (type === 'minus') {
                  if (currentVal > input.attr('min')) {
                    input.val(currentVal - 1).change();
                  } 
                  if (parseInt(input.val()) === input.attr('min')) {
                    $(this).attr('disabled', true);
                  }
                } else if (type === 'plus') {
                  if (currentVal < input.attr('max')) {
                    input.val(currentVal + 1).change();
                  }
                  if(parseInt(input.val()) === input.attr('max')) {
                    $(this).attr('disabled', true);
                  }
                }
              } else {
                input.val(0);
              }
            });

            $('.input-number', this).focusin(function(){
              $(this).data('oldValue', $(this).val());
            });
          
            $('.input-number', this).change(function() {  
              minValue =  parseInt($(this).attr('min'));
              maxValue =  parseInt($(this).attr('max'));
              valueCurrent = parseInt($(this).val());
              namef = $(this).attr('name');

              if(valueCurrent >= minValue) {
                $(".btn-number[data-type='minus'][data-field='"+namef+"']").removeAttr('disabled');
              } else {
                alert('Sorry, the minimum value was reached');
                $(this).val($(this).data('oldValue'));
              }
              if(valueCurrent <= maxValue) {
                $(".btn-number[data-type='plus'][data-field='"+namef+"']").removeAttr('disabled');
              } else {
                alert('Sorry, the maximum value was reached');
                $(this).val($(this).data('oldValue'));
              }
            });

            $(".input-number", this).keydown(function (e) {
              // Allow: backspace, delete, tab, escape, enter and .
              if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) || 
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                  // let it happen, don't do anything
                  return;
              }
              // Ensure that it is a number and stop the keypress
              if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
              }
            });
          });
        }
      }, // init
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      } // finalize
     },
     // Home page
     'home': {
       init: function() {
         // JavaScript to be fired on the home page
         var options = {
            accessibility: true,
            prevNextButtons: true,
            pageDots: true,
            setGallerySize: false,
            arrowShape: {
              x0: 10,
              x1: 60,
              y1: 50,
              x2: 60,
              y2: 45,
              x3: 15
            }
         };

         var carousel = document.querySelector('[data-carousel]');
         var slides = document.getElementsByClassName('carousel-cell');
         var flkty = new Flickity(carousel, options);
         flkty.on('scroll', function () {
            flkty.slides.forEach(function (slide, i) {
              var image = slides[i];
              var x = (slide.target + flkty.x) * -1/3;
              image.style.backgroundPosition = x + 'px center';
            });
         });
       },
       finalize: function() {
         // JavaScript to be fired on the home page, after the init JS
         $('.home .owl-carousel').owlCarousel({
           center: true,
           loop: true,
           nav: true,
           navText: ['<span>Next</span>','<span>Prev</span>'],
           responsive:{
                0:    { items: 1 },
                576:  { items: 2 },
                768:  { items: 3 },
                992:  { items: 4 },
                1441: { items: 5 }
            }
         });
       }
     },
     'webinar': {
       init: function(){
        var date = new Date("May 15, 2018 11:00:00");
        var now = new Date();
        var diff = (date.getTime()/1000) - (now.getTime()/1000);
        
        var clock = $('.webinar-countdown').FlipClock(diff, {
          clockFace: 'DailyCounter',
          countdown: true,
          language: 'spanish'
        });
       }
     },
     'single':{
       init: function(){}, // init
       finalize: function(){
        $('.store-single .owl-carousel').owlCarousel({
          items: 1,
          loop: true,
          nav: true,
          navText: ['<span>Next</span>','<span>Prev</span>'],
        });
       } // finalize
     }
   };

   // The routing fires all common scripts, followed by the page specific scripts.
   // Add additional events for more control over timing e.g. a finalize event
   var UTIL = {
     fire: function(func, funcname, args) {
       var fire;
       var namespace = Sage;
       funcname = (funcname === undefined) ? 'init' : funcname;
       fire = func !== '';
       fire = fire && namespace[func];
       fire = fire && typeof namespace[func][funcname] === 'function';

       if (fire) {
         namespace[func][funcname](args);
       }
     },
     loadEvents: function() {
       // Fire common init JS
       UTIL.fire('common');

       // Fire page-specific init JS, and then finalize JS
       $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
         UTIL.fire(classnm);
         UTIL.fire(classnm, 'finalize');
       });

       // Fire common finalize JS
       UTIL.fire('common', 'finalize');
     }
   };

   // Load Events
   $(document).ready(UTIL.loadEvents);

 })(jQuery); // Fully reference jQuery after this point.

 // Extra Functions
